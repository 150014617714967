export const sexAnswer = {
    male: "male",
    female: "female",
} as const;

export const ageAnswer = {
    eighteenToTwentyFour: "18-24",
    twentyFiveToThirtyFour: "25-34",
    thirtyFiveToFortyFour: "35-44",
    fortyFiveToFiftyFour: "45-54",
    fiftyFiveToSixtyFour: "55-64",
    sixtyFivePlus: "65+",
} as const;
